import React, { FC, ReactNode } from "react";

export interface ITeamMemberCard {
  img: ReactNode;
  name: string;
  position: string;
  socialMediaLinks: ITeamMemberCardLink[];
}

export interface ITeamMemberCardLink {
  link: string;
  icon: ReactNode;
}

export const TeamMemberCard: FC<ITeamMemberCard> = ({
  img,
  name,
  position,
  socialMediaLinks,
}) => {
  return (
    <div className="member">
      <div className="member-img">
        {img}
        <div className="social">
          {socialMediaLinks.map((sml, index) => (
            <a key={index} href={sml.link}>
              {sml.icon}
            </a>
          ))}
        </div>
      </div>
      <div className="member-info">
        <h4>{name}</h4>
        <span>{position}</span>
      </div>
    </div>
  );
};
