/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { StaticImage } from "gatsby-plugin-image";
import { FC } from "react";
import {
  FaArtstation,
  FaCogs,
  FaGamepad,
  FaInstagram,
  FaLinkedin,
  FaSoundcloud,
  FaSpotify,
  FaStar,
  FaTwitter,
} from "react-icons/fa";
import { FadeInSection } from "../components/FadeInSection";
import Layout from "../components/Layout/Layout";
import { TeamMemberCard } from "../components/TeamMemberCard";

const About: FC = () => {
  return (
    <Layout currentPage="/about" pathname="/about" title="Beartwigs - About">
      <section id="hero-about">
        <div className="hero-container">
          <h1
            css={css`
              color: white;
              text-shadow: 0px 4px 18px #000000;
              font-family: Palanquin;
            `}
          >
            Reinventing the retro style
          </h1>
        </div>
      </section>
      <section className="about-title">
        <div className="container">
          <div className="section-title">
            <h2>About us</h2>
            <FadeInSection time={1000} threshold={0.8}>
              <h3>A game studio based in Linköping, Sweden</h3>
              <p>
                Beartwigs was founded in Linköping after a couple of game jams
                involving their founders. The goal for the first product became
                to create an expansive adventure game to satisfy the passion for
                adventure and exploration, but since there were no suitable
                satisfying tools available for the development it was decided to
                innovate. As a consequence, Beartwigs is right now as much a
                technology company as it is a game studio.
              </p>
            </FadeInSection>
          </div>
        </div>
      </section>
      <section className="about">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-12 d-flex justify-content-center align-items-stretch"
              style={{
                background: "url(image1.png) bottom center",
                backgroundSize: "cover",
                minHeight: "500px",
              }}
            ></div>
            <div className="col-lg-6 col-md-12 d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
              <div className="box-heading">
                <h3>Pillars of development</h3>
                <p>
                  When Beartwigs was established, we setup three pillars to
                  represent our product vision.
                </p>
              </div>
              <div className="icon-box">
                <div className="icon">
                  <FaGamepad style={{ fontSize: "28px", color: "#8fc04e" }} />
                </div>
                <h4 className="title">Gameplay first</h4>
                <p className="description">
                  We believe that immersive experiences come from the player's
                  own incentive to overcome obstacles. Give them a challenge!
                </p>
              </div>
              <div className="icon-box">
                <div className="icon">
                  <FaStar style={{ fontSize: "28px", color: "#8fc04e" }} />
                </div>
                <h4 className="title">Go beyond the status quo</h4>
                <p className="description">
                  Excel at what we do by creating what we desire. Every
                  individual of the team are passionate fans of the game markets
                  we target.
                </p>
              </div>
              <div className="icon-box">
                <div className="icon">
                  <FaCogs style={{ fontSize: "28px", color: "#8fc04e" }} />
                </div>
                <h4 className="title">Innovate and improve</h4>
                <p className="description">
                  Realising the best ideas requires innovation. If the
                  technology does not exist, we create it!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="team" className="team">
        <div className="container">
          <div className="section-title">
            <h2>Team</h2>
            <h3>
              Our Awesome <span>Team</span>
            </h3>
            <p></p>
          </div>
          <div className="row">
            <FadeInSection
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              time={1000}
            >
              <TeamMemberCard
                img={<StaticImage src="../images/p1.jpg" alt="" />}
                name="Sam Bloom"
                position="Module Exporter"
                socialMediaLinks={[
                  {
                    icon: <FaLinkedin />,
                    link: "https://www.linkedin.com/in/sambl/",
                  },
                  {
                    icon: <FaTwitter />,
                    link: "https://twitter.com/samthetwig",
                  },
                ]}
              />
            </FadeInSection>
            <FadeInSection
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              time={1000}
              delay={200}
            >
              <TeamMemberCard
                img={<StaticImage src="../images/p2.jpg" alt="" />}
                name="Björn Detterfelt"
                position="Namespace Coordinator"
                socialMediaLinks={[
                  {
                    icon: <FaLinkedin />,
                    link: "https://www.linkedin.com/in/bjode/",
                  },
                ]}
              />
            </FadeInSection>
            <FadeInSection
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              time={1000}
              delay={400}
            >
              <TeamMemberCard
                img={<StaticImage src="../images/andreas.png" alt="" />}
                name="Andréas Söderberg"
                position="Universal Graphical Genius (UGG)"
                socialMediaLinks={[
                  {
                    icon: <FaLinkedin />,
                    link: "https://www.linkedin.com/in/ajsoderberg/",
                  },
                  {
                    icon: <FaTwitter />,
                    link: "https://twitter.com/3Dreas",
                  },
                ]}
              />
            </FadeInSection>
            <FadeInSection
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              time={1000}
              delay={600}
            >
              <TeamMemberCard
                img={<StaticImage src="../images/p3.jpg" alt="" />}
                name="Magnus Chirgwin"
                position="Chief Reverb Officer"
                socialMediaLinks={[
                  {
                    icon: <FaLinkedin />,
                    link: "https://www.linkedin.com/in/magnus-chirgwin-20a4891a0/",
                  },
                  {
                    icon: <FaInstagram />,
                    link: "https://www.instagram.com/magnuschirgwin/",
                  },
                ]}
              />
            </FadeInSection>
            <FadeInSection
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              time={1000}
              delay={800}
            >
              <TeamMemberCard
                img={<StaticImage src="../images/p4.jpg" alt="" />}
                name="Axel Björkqvist"
                position="Instrument Distributor"
                socialMediaLinks={[
                  {
                    icon: <FaLinkedin />,
                    link: "https://www.linkedin.com/in/axel-bj%C3%B6rkqvist-66510917a/",
                  },
                  {
                    icon: <FaSoundcloud />,
                    link: "https://soundcloud.com/wunjan",
                  },
                  {
                    icon: <FaSpotify />,
                    link: "https://open.spotify.com/artist/2VyPejP91a0RxutQMswJEM",
                  },
                ]}
              />
            </FadeInSection>
            <FadeInSection
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              time={1000}
              delay={1000}
            >
              <TeamMemberCard
                img={<StaticImage src="../images/victor.jpg" alt="" />}
                name="Victor Weidar"
                position="Dungeon Dwelver"
                socialMediaLinks={[
                  {
                    icon: <FaLinkedin />,
                    link: "https://www.linkedin.com/in/victor-w-6b981980/",
                  },
                  {
                    icon: <FaTwitter />,
                    link: "https://twitter.com/victorweidar",
                  },
                ]}
              />
            </FadeInSection>
            <FadeInSection
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              time={1000}
              delay={1000}
            >
              <TeamMemberCard
                img={<StaticImage src="../images/josh.jpg" alt="" />}
                name="Joshua Robbins"
                position="Pixel Assembler"
                socialMediaLinks={[
                  {
                    icon: <FaArtstation />,
                    link: "https://www.artstation.com/joshoclast",
                  },
                ]}
              />
            </FadeInSection>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default About;
